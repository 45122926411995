const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload,
      };
    case 'SET_MOVIES':
      return {
        ...state,
        movies: action.payload,
      };
    case 'APPEND_MOVIES':
      return {
        ...state,
        movies: [...state.movies, ...action.payload],
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
