const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_DATA':
      return {
        ...state,
        trailers: action.payload.trailers,
        reviews: action.payload.reviews,
        similar: action.payload.similar,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
