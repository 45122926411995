import React, { useEffect } from 'react';
import useMovieDetails from './hooks/useMovieDetails';
import {
  Expandable,
  Flex,
  Overview,
  Reviews as ReviewsContainer,
  Separator,
  SimilarContainer,
  Grid,
} from './styled';

const Reviews = ({ reviews }) => (
  <ReviewsContainer>
    <h4>Reviews</h4>
    {reviews.map(({ id, content }) => (
      <Overview key={id}>{content}</Overview>
    ))}
  </ReviewsContainer>
);

const Similars = ({ similar }) => (
  <>
    {Boolean(similar.length) && (
      <SimilarContainer>
        <h4>More like this</h4>
        <Grid>
          {similar.map(({ id, poster_path }) => (
            <a key={id} href={`https://www.themoviedb.org/movie/${id}`}>
              <img
                src={`https://image.tmdb.org/t/p/w92${poster_path}`}
                alt="movie poster"
              />
            </a>
          ))}
        </Grid>
      </SimilarContainer>
    )}
  </>
);

const Details = ({ id, expand }) => {
  const [{ reviews, similar, trailers }, fetchDetails] = useMovieDetails(id);

  useEffect(() => {
    if (expand) {
      fetchDetails();
    }
  }, [expand]);

  return (
    <>
      <Expandable data-test="details" size={expand ? '785' : '0'}>
        <Separator />
        <Flex>
          <Reviews reviews={reviews} />
          <Similars similar={similar} />
        </Flex>
        {Boolean(trailers.length) && (
          <iframe
            title="Trailer"
            width="796"
            height="390"
            src={`https://www.youtube.com/embed/${trailers[0].key}`}
          ></iframe>
        )}
      </Expandable>
    </>
  );
};

export default Details;
