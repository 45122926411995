import { useReducer } from 'react';
import { pipe, filter, isEmpty, take, prop } from 'ramda';
import { fetchReviews, fetchVideos, fetchSimilar } from '../../http-client';
import reducer from './reducer';

const pickMoviesWithPoster = pipe(
  filter(prop('poster_path')),
  take(6),
);

const initialState = {
  error: undefined,
  trailers: [],
  reviews: [],
  similar: [],
  loading: false,
};

const withFallbackReviews = arr =>
  isEmpty(arr)
    ? [{ id: '1', content: 'No reviews exist for this  movie' }]
    : take(2, arr);

const useMovieDetails = id => {
  const [{ reviews, similar, trailers, error, loading }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const setData = payload => dispatch({ type: 'SET_DATA', payload });
  const setLoading = payload => dispatch({ type: 'SET_LOADING', payload });
  const setError = payload => dispatch({ type: 'SET_LOADING', payload });

  const fetchDetails = async () => {
    setLoading(true);
    try {
      const [reviews, trailers, similar] = await Promise.all([
        fetchReviews(id),
        fetchVideos(id),
        fetchSimilar(id),
      ]);

      const payload = {
        reviews: withFallbackReviews(reviews.results),
        trailers: trailers.results,
        similar: pickMoviesWithPoster(similar.results),
      };

      setData(payload);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return [{ reviews, similar, trailers, error, loading }, fetchDetails];
};

export default useMovieDetails;
