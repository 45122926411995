const makeUrl = (path, params = '') =>
  `https://api.themoviedb.org/3/${path}?api_key=${process.env.REACT_APP_API_KEY}${params}`;

const toJson = data => data.json();

export const fetchPlayingNow = (page = 1) =>
  fetch(makeUrl('movie/now_playing', `&page=${page}`)).then(toJson);

export const fetchGenres = () =>
  fetch(makeUrl('genre/movie/list')).then(toJson);

export const fetchReviews = id =>
  fetch(makeUrl(`movie/${id}/reviews`)).then(toJson);
export const fetchVideos = id =>
  fetch(makeUrl(`movie/${id}/videos`)).then(toJson);
export const fetchSimilar = id =>
  fetch(makeUrl(`movie/${id}/similar`)).then(toJson);

export const search = query => page =>
  fetch(makeUrl('search/movie', `&query=${query}`)).then(toJson);
