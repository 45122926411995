import { useEffect } from 'react';

const atBottomPoint = () =>
  window.innerHeight + document.documentElement.scrollTop ===
  document.documentElement.offsetHeight;

const useInfiniteScroll = (callback, loading) => {
  const handleScroll = () => atBottomPoint() && !loading && callback();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading]);
};

export default useInfiniteScroll;
