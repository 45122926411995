import { useReducer } from 'react';
import { fetchPlayingNow, fetchGenres, search } from '../../http-client';
import reducer from './reducer';
import normalize from './normalize';

const initialState = {
  page: 1,
  error: undefined,
  movies: [],
  loading: false,
};

const useMovies = () => {
  const [{ movies, error, loading, page }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const clear = () => dispatch({ type: 'SET_MOVIES', payload: [] });

  const appendMovies = payload => dispatch({ type: 'APPEND_MOVIES', payload });

  const setPage = payload => dispatch({ type: 'SET_PAGE', payload });
  const nextPage = () => setPage(page + 1);
  const resetPage = () => setPage(1);

  const setError = error => dispatch({ type: 'SET_ERROR', error });

  const setLoading = loading =>
    dispatch({
      type: 'SET_LOADING',
      payload: Boolean(loading),
    });

  const fetchMovies = async query => {
    setLoading(true);

    const moviesCall = query ? search(query) : fetchPlayingNow;

    try {
      const [movies, genres] = await Promise.all([
        moviesCall(page),
        fetchGenres(),
      ]);

      appendMovies(normalize(movies.results, genres.genres));
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    clear,
    error,
    fetchMovies,
    loading,
    movies,
    nextPage,
    resetPage,
    page,
    setError,
    setLoading,
  };
};

export default useMovies;
