const imageUrl = `https://image.tmdb.org/t/p/w300`;

const normalize = (movies, genres) =>
  movies.map(movie => ({
    ...movie,
    poster_path: `${imageUrl}${movie.poster_path}`,
    genres: movie.genre_ids.map(id => {
      const iterator = genre => genre.id === id;
      const { name = '-' } = genres.find(iterator);

      return name;
    }),
  }));

export default normalize;
