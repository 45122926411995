import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -7px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  min-height: 300px;
  margin: 25px auto 25px;
  width: 800px;
  text-align: left;
`;

export const Poster = styled.img`
  width: 220px;
`;

export const Overview = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export const Info = styled.div`
  width: 60%;
  padding: 0 20px;
`;

export const Flex = styled.div`
  display: flex;
  position: relative;
`;

export const Pointer = styled(Flex)`
  cursor: pointer;
`;

export const Reviews = styled.div`
  flex-grow: 1;
  padding: 0px 10px;
  p {
    -webkit-line-clamp: 6;
  }
`;

export const Separator = styled.div`
  height: 1px;
  background-color: #ccc;
`;

export const Expandable = styled.div`
  max-height: ${({ size }) => size}px;
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
`;

export const Input = styled.input`
  box-sizing: border-box;
  outline: none;
  padding: 7px;
  border: none;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #07f;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-gap: 10px;
  img {
    &:hover {
      box-shadow: 0 0 0 2px #f3ce13;
    }
  }
`;

export const SimilarContainer = styled.div`
  padding-left: 10px;
`;

export const Tag = styled.div`
  border: 1px solid #999;
  border-radius: 3px;
  padding: 0.1rem 0.3rem;
  font-size: 0.8rem;
  margin-right: 0.3rem;
`;
