import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'ramda';
import useMovies from './hooks/useMovies';
import useDebounce from './hooks/useDebounce';
import { Card, Info, Input, Flex, Overview, Poster, Tag } from './styled';
import useInfiniteScroll from './hooks/useInfiniteScroll';
import Loader from './components/Loader';
import Details from './Details';

const Button = styled.button`
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  background-color: #ccc;
  padding: 0.3rem 0.6rem;
`;
const Container = styled.div`
  text-align: center;
`;
const Content = styled.div`
  background-color: #eee;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Movie = ({ movie }) => {
  const [expand, setExpand] = useState();
  const toggle = () => setExpand(expand => !expand);

  return (
    <Card>
      <Flex>
        <Poster src={movie.poster_path} />
        <Info>
          <h3 data-test="movie-title">{movie.title}</h3>
          <p>Released: {movie.release_date}</p>
          <h4>Rating: {movie.vote_average}</h4>
          <Overview>{movie.overview}</Overview>

          <Flex>
            {movie.genres &&
              movie.genres.map(genre => (
                <Tag key={`${movie.id}-${genre}`}>{genre}</Tag>
              ))}
          </Flex>
          <Button data-test="see-more" onClick={toggle}>
            See more
          </Button>
        </Info>
      </Flex>
      <Details id={movie.id} expand={expand} />
    </Card>
  );
};

export const MoviesList = ({
  movies,
  loading,
  page,
  fetchMovies,
  nextPage,
}) => {
  useInfiniteScroll(nextPage, loading);

  return (
    <div data-test="list">
      {movies.map(movie => (
        <Movie key={movie.id} movie={movie} />
      ))}
    </div>
  );
};

const App = () => {
  const {
    clear,
    error,
    fetchMovies,
    loading,
    movies,
    nextPage,
    resetPage,
    page,
  } = useMovies();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const handleTextChange = e => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    clear();
    resetPage();
  }, [debouncedQuery]);

  useEffect(() => {
    fetchMovies(debouncedQuery);
  }, [page, debouncedQuery]);

  return (
    <Container>
      <h1>Movie Rama</h1>
      <Input
        onChange={handleTextChange}
        type="text"
        id="search"
        value={query}
        placeholder="Search for a movie..."
      />
      <Content>
        {error && <div>{error}</div>}
        {isEmpty(movies) && !loading ? (
          <div>
            Oops, no results were found. Try searching for something else.
          </div>
        ) : (
          <MoviesList
            nextPage={nextPage}
            page={page}
            fetchMovies={fetchMovies}
            movies={movies}
            loading={loading}
          />
        )}
        {loading && <Loader />}
      </Content>
    </Container>
  );
};

export default App;
